<template>
 <v-row class="pa-0 mx-0">
      <template v-for="(item, index) in aliasFieldsList">
        <v-col class="pa-1" v-if="!item.isPanelField && ((item.conditionscript && item.isShowElement) ? item.isShowElement ? item.isShowElement(fields, self) : () => {} : item.isShow)" :cols="$vuetify.breakpoint.mdAndDown ? ($vuetify.breakpoint.xsOnly || [10, 11, 12, 14, 16, 18].includes(item.type)) ? '12' : '6' : item.columns && item.type !== 16 ? item.columns : '12'" :md="item.columns && item.type !== 16 ? item.columns : [10, 11, 12, 14, 16, 18].includes(item.type) ? '12' : '6'" :key="index">
          <template v-if="item.type === 1">
            <v-text-field :id="`_${item.name}`" dense outlined hide-details :label="item.label" :rules="getValidation(item.validation) || []" v-model="fields[item.name]" :placeholder="item.placeholder" @change="checkForValidationOrFunction(item, fields[item.name], index)"></v-text-field>
          </template>
          <template v-if="item.type === 2">
            <label>{{ item.label ? item.label : '' }}</label>
            <!-- <vue-editor v-model="fields[item.name]"></vue-editor> -->
            <v-textarea :id="`_${item.name}`" dense outlined hide-details :label="item.label" :rules="getValidation(item.validation) || []" v-model="fields[item.name]" :placeholder="item.placeholder" @change="checkForValidationOrFunction(item, fields[item.name], index)"></v-textarea>
          </template>
          <template v-if="item.type === 3">
            <v-row>
              <v-col class="py-0" cols="12" v-if="item.label">
                <label>{{ item.label }}:</label>
              </v-col>
            </v-row>
            <v-row class="mx-0" v-if="item.default_value.viewType === 'horizontal'">
              <v-col cols="12" class="pa-0 scroll-styles">
                <v-card class="d-flex flex-row" flat tile>
                  <v-card id="datagrid-container" tile flat :width="`${getCheckboxLabelWidth(options)}px`" min-height="60px" :min-width="`${getCheckboxLabelWidth(options)}px`" v-for="(options, index) in getActiveOptions(item.default_value)" :key="index">
                    <v-row class="mx-0" no-gutters>
                      <v-checkbox @change="checkForValidationOrFunction(item, fields[item.name], index)" class="checkbox_position" hide-details v-model="fields[item.name]" :rules="getValidation(item.validation, 'multiple') || []" :label="options.label" :value="options.value" multiple></v-checkbox>
                    </v-row>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
            <v-layout v-else row wrap class="mx-0" :style="`${item.default_value.viewType === 'vertical' ? 'display:block' : ''}`">
              <v-flex :class="xs12" v-for="(options, index) in getActiveOptions(item.default_value)" :key="index">
                <v-checkbox @change="checkForValidationOrFunction(item, fields[item.name], index)" class="checkbox_position" hide-details v-model="fields[item.name]" :rules="getValidation(item.validation, 'multiple') || []" :label="options.label" :value="options.value" multiple></v-checkbox>
              </v-flex>
            </v-layout>
          </template>
          <template v-if="item.type === 4">
            <label>{{ item.label }}</label>
            <v-radio-group dense row hide-details v-model="fields[item.name]" :rules="getValidation(item.validation) || []" @change="checkForValidationOrFunction(item, fields[item.name], index)">
              <v-radio v-for="(options, index) in item.default_value.options" :key="index" :label="options.label" :value="options.value" @click="handleRadioButtonClick(item, options, fields[item.name])"></v-radio>
            </v-radio-group>
          </template>
          <template v-if="item.type === 5">
            <template v-if="item.default_value.isAPI">
              <v-autocomplete dense  :id="`_${item.name}`" outlined :items="item.default_value.options" :label="item.label" :rules="getValidation(item.validation) || []" v-model="fields[item.name]" :key="autoCompleteRender"
              @keyup="getValuesFromAPI(`searchInput${index}`, item.default_value, index)" autocomplete="off" :ref="`searchInput${index}`"
              :placeholder="item.placeholder" clearable hide-details :item-text="item.default_value.name" :item-value="item.default_value.name" @change="checkForValidationOrFunction(item, fields[item.name], index)"></v-autocomplete>
            </template>
            <template v-else-if="item.default_value.selectType !== undefined ?  item.default_value.selectType === 'default' : true">
              <v-autocomplete dense  :id="`_${item.name}`" outlined :items="getActiveOptions(item.default_value)" :rules="item.validation ? $_multiSelectValidation : []" :label="item.label"
                :multiple="item.default_value.is_multiselect" v-model="fields[item.name]" :placeholder="item.placeholder" clearable hide-details
                item-text="label" item-value="value" @change="checkForValidationOrFunction(item, fields[item.name], index)"></v-autocomplete>
            </template>
            <template v-else-if="item.default_value.selectType === 'custom'">
              <v-autocomplete dense hide-details outlined
                :item-text="item.optionName" :item-value="item.optionValue" :items="item.customOptions"
                :multiple="item.default_value.is_multiselect" v-model="fields[item.name]" :placeholder="item.placeholder" clearable
                :label="$t(item.label)" :id="`_${item.name}`" attach
                @change="checkForValidationOrFunction(item, fields[item.name], index)">
              </v-autocomplete>
            </template>
          </template>
          <template v-if="item.type === 6">
            <v-menu v-model="item.date_menu" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on }">
                <v-text-field :id="`_${item.name}`" dense outlined hide-details v-model="fields[item.name]" :placeholder="item.placeholder" :rules="getValidation(item.validation) || []" clearable prepend-icon="mdi-calendar" :label="item.label" readonly v-on="on" @click:clear="fields[item.name] = null" @change="checkForValidationOrFunction(item, fields[item.name], index)"></v-text-field>
              </template>
              <v-date-picker v-model="item.date_value" @input="fields[item.name] = parseDate(item.date_value), item.date_menu = false"></v-date-picker>
            </v-menu>
          </template>
          <template v-if="item.type === 7">
            <template v-if="item && item.default_value && !item.default_value.panelId">
              <v-menu :ref="`time_menu_${index}`" v-model="item.time_menu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="fields[item.name]" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field :id="`_${item.name}`" dense outlined hide-details v-model="fields[item.name]" :rules="getValidation(item.validation) || []" :placeholder="item.placeholder" clearable prepend-icon="mdi-timer" :label="item.label" readonly v-on="on" @click:clear="fields[item.name] = null" @change="checkForValidationOrFunction(item, fields[item.name], index)"></v-text-field>
                </template>
                <v-time-picker v-if="item.time_menu" v-model="fields[item.name]" format="24hr" full-width @click:minute="$refs[`time_menu_${index}`][0].save(fields[item.name])"></v-time-picker>
              </v-menu>
            </template>
            <template v-else>
              <v-menu :ref="`time_menu_${index}`" v-model="menuTime" :close-on-content-click="false" :nudge-right="40" :return-value.sync="fields[item.name]" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field :id="`_${item.name}`" dense outlined hide-details v-model="fields[item.name]" :rules="getValidation(item.validation) || []" :placeholder="item.placeholder" clearable prepend-icon="mdi-timer" :label="item.label" readonly v-on="on" @click:clear="fields[item.name] = null" @change="checkForValidationOrFunction(item, fields[item.name], index)"></v-text-field>
                </template>
                <v-time-picker v-if="menuTime" v-model="fields[item.name]" format="24hr" full-width @click:minute="$refs[`time_menu_${index}`][0].save(fields[item.name])"></v-time-picker>
              </v-menu>
            </template>
          </template>
          <template v-if="item.type === 8">
            <v-switch dense inset :label="item.label" :rules="getValidation(item.validation) || []" v-model="fields[item.name]" hide-details></v-switch>
          </template>
          <template v-if="item.type === 9">
            <input v-if="isIos" type="file" prepend-icon="mdi-camera" @change="handleFileUpload($event, item._id, item.name)" :label="item.label" multiple class="mx-1" />
            <v-file-input v-else outlined :label="item.label" multiple @change="uploadDocumentHandler(item._id, item.name, fields[item.name]['attachments'])" :rules="getValidation(item.validation) || []" v-model="fields[item.name]['attachments']" hide-details :placeholder="item.placeholder"></v-file-input>
            <v-row class="mx-0 ml-8" :key="reRender">
              <v-col cols="12" class="pa-0" v-for="(document, docIndex) in listOfDocuments[item.name]" :key="document._id">
                <v-layout>
                  <p class="pa-0 ma-0 my-auto mr-3">{{document.file_original_name}}</p>
                  <v-spacer></v-spacer>
                  <v-btn icon color="info" @click="downloadDocument(document._id)">
                    <v-scale-transition>
                      <v-icon>mdi-cloud-download</v-icon>
                    </v-scale-transition>
                  </v-btn>
                  <v-btn icon color="error" @click="deleteDocument(document._id, item.name)">
                    <v-scale-transition>
                      <v-icon>mdi-delete-circle</v-icon>
                    </v-scale-transition>
                  </v-btn>
                </v-layout>
                <v-divider v-if="listOfDocuments[item.name].length > (docIndex + 1)"></v-divider>
              </v-col>
            </v-row>
          </template>
          <template v-if="item.type === 10">
            <v-card outlined class="pa-0">
              <v-card-title class="pa-1 subtitle-1">{{ item.label }}
                <v-radio-group :id="`_${item.name}`" dense v-model="fields[item.name]['answer']" row class="ml-2 mt-6">
                  <v-radio :label="item.text" :value="item.value" v-for="(item, index) in statusList" :key="index"></v-radio>
                </v-radio-group>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-0">
                <v-tabs>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab>
                    <v-icon>mdi-comment-text-outline</v-icon>
                    {{ $t('message.common.comments') }}
                  </v-tab>
                  <v-tab>
                    <v-icon class="mdi-rotate-270">mdi-attachment</v-icon>
                     {{ $t('message.mixed.attachments') }}
                    <v-avatar left class="primary ml-2" for size="25"><span class="white--text">{{ listOfDocuments[item.name] && listOfDocuments[item.name].length > 0 ? (listOfDocuments[item.name].length) : '0' }}</span></v-avatar>
                  </v-tab>
                  <v-tab-item>
                    <v-divider></v-divider>
                    <v-card flat tile>
                      <v-card-text>
                        <v-textarea dense hide-details outlined v-model="fields[item.name]['comments']"></v-textarea>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-divider></v-divider>
                    <v-card flat tile :key="reRender">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <input v-if="isIos" type="file" prepend-icon="mdi-camera" @change="handleFileUpload($event, item._id, item.name)" :label="item.label" multiple class="mx-1" />
                            <v-file-input v-else label="Documents" hide-details multiple @change="uploadDocumentHandler(item._id, item.name, fields[item.name]['attachments'])" v-model="fields[item.name]['attachments']" outlined dense></v-file-input>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="12" lg="1" md="2" sm="3" v-for="(document) in listOfDocuments[item.name]" :key="document._id" class="pa-0 pl-2 mt-1">
                            <v-hover v-slot:default="{ hover }">
                              <v-card :elevation="hover ? 14 : 1" shaped flat>
                                <v-card-text class="text-center pa-1">
                                  <template v-if="document.imgObj && document.imgObj.url">
                                    <v-img contain height="50" :lazy-src="document.imgObj.url" :src="document.imgObj.url"></v-img>
                                  </template>
                                  <v-avatar size="52" class="profile" v-else>
                                    <v-icon :size="50" :color="document.imgObj.color">{{ document.imgObj.icon }}</v-icon>
                                  </v-avatar>
                                </v-card-text>
                                <v-card-subtitle class="pa-1 text-center text-truncate">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <span v-on="on" class="font-weight-bold">{{ document.file_original_name }}</span>
                                    </template>
                                    <span>{{ document.file_original_name }}</span>
                                  </v-tooltip>
                                </v-card-subtitle>
                                <v-divider></v-divider>
                                <v-card-actions class="pa-0">
                                  <v-spacer></v-spacer>
                                  <v-btn icon color="primary" @click="editDocument(document, item.name)">
                                    <v-scale-transition>
                                      <v-icon v-if="hover">mdi-lead-pencil</v-icon>
                                    </v-scale-transition>
                                  </v-btn>
                                  <v-btn icon color="info" @click="downloadDocument(document._id)">
                                    <v-scale-transition>
                                      <v-icon v-if="hover">mdi-cloud-download</v-icon>
                                    </v-scale-transition>
                                  </v-btn>
                                  <v-btn icon color="error" @click="deleteDocument(document._id, item.name, index)">
                                    <v-scale-transition>
                                      <v-icon v-if="hover">mdi-delete-circle</v-icon>
                                    </v-scale-transition>
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-hover>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-card-text>
            </v-card>
          </template>
          <template v-if="item.type === 11">
            <v-subheader v-if="item.label" class='pa-0'><b>{{ item.label }}:</b></v-subheader>
            <v-divider></v-divider>
          </template>
          <template v-if="item.type === 12">
            <v-card class="d-flex align-content-start flex-wrap  pa-0" flat tile>
              <v-card min-width="80" max-width="80" :title="grid.label" v-for="(grid, gIndex) in item.default_value.grid_data.labels" :key="gIndex" class="pa-0 pl-1 text-left text-truncate" outlined tile>
                <span class="subtitle-2">{{ grid.label }}</span>
              </v-card>
            </v-card>
            <v-card class="d-flex align-content-start flex-wrap  pa-0" flat tile v-for="(row, rIndex) in item.default_value.grid_data.data" :key="rIndex">
              <v-card max-width="80" class="pa-0" outlined tile v-for="(grid, dIndex) in row" :key="dIndex">
                <v-text-field single-line dense flat full-width v-model="grid.value" solo hide-details></v-text-field>
              </v-card>
            </v-card>
            <v-btn class="mt-2" fab dark small color="primary" @click="addnewLineOnGrid(index, item.label, item)">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
        <template v-if="item.type === 13">
          <v-subheader v-if="item.label" class='pa-0'><b>{{ item.label }}:</b></v-subheader>
          <VueSignaturePad width="100%" :height="`${$formatter.$_setSignaturePadHeight(item)}px`" class="signature--pad" :ref="`signaturePad_${item._id}`"/>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="info" fab x-small dark v-on="on" class="mt-1" @click="clearThisSignature(item._id)">
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </template>
            <span>Reset</span>
          </v-tooltip>
        </template>
          <template v-if="item.type === 14">
            <v-card class="pa-1">
              <v-card-title class="pa-1 caption font-weight-bold">
                {{ item.label }}
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-1">
                <form-render :aliasFieldsList="item.panel_fields" :listOfDocuments="listOfDocuments" ref="formReference" :id="`panel_${item._id}`" :fields="fields" :checklistId="checklistId">
                </form-render>
              </v-card-text>
            </v-card>
          </template>
          <template v-if="item.type === 15">
            <span class='pa-0 subtitle-2' :style="{ fontSize: `${item.default_value.font_size}em !important` }">{{ item.label }}</span>
          </template>
          <template v-if="item.type === 16">
            <div v-html="item.default_value.html_content"></div>
          </template>
          <template v-if="item.type === 17">
            <div class="proptable">
              <table width="100%" border="0" class="dense_table">
                <thead>
                  <th width="20%" v-for="(grid, index) in item.default_value.grid_header.labels" :key="index">
                    {{ grid.label}}
                  </th>
                </thead>
                <tbody>
                  <tr v-for="(row, rIndex) in item.default_value.grid_data.values" :key="rIndex">
                    <td v-for="(grid, dIndex) in item.default_value.grid_header.labels" :key="dIndex" width="20%" :style="dIndex === 0 ? 'padding:0px': 'padding:0px;border: none'">
                      <template v-if="dIndex === 0">
                        {{ row.name }}
                      </template>
                      <template v-else>
                        <tr>
                          <td width="60%" class="pa-1">
                            <v-text-field dense flat full-width v-model="row[`compValue_${dIndex}`]" solo hide-details></v-text-field>
                          </td>
                          <td  width="30%" class="pa-1">
                            <v-select dense flat full-width hide-details :items="inventCompItem" v-model="row[`compSelect_${dIndex}`]"></v-select>
                          </td>
                        </tr>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <template v-if="item.type === 18"><!-- DataGrid Component -->
            <v-row>
              <v-col cols="12" lg="5" md="6" class="py-1">
                <v-icon class="float-start" small color="grey darken-3" dark>mdi-information</v-icon>
                <span class="mx-2 pb-4 font-weight-bold">{{ $t('message.common.dragAndDropTips') }}</span>
              </v-col>
              <v-col cols="12" lg="5" md="6" class="py-1">
                <v-icon class="float-start" small color="grey darken-3" dark>mdi-information</v-icon>
                <span class="mx-2 pb-4 font-weight-bold">{{ $t('message.common.afterCopyFilter') }}</span>
              </v-col>
            </v-row>
            <template>
              <v-expansion-panels class="my-2">
                <v-expansion-panel v-for="(gridlist, idx) in item.gird_filter_data" :key="idx">
                  <v-expansion-panel-header color="primary" class="white--text" v-if="gridlist === item.name"> {{ $t('message.checklist.apply') }} {{ gridlist.toString().split('_')[0].length ? gridlist.toString().split('_')[0] : gridlist }} {{ $t('message.checklist.filter') }}  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card outlined class="py-1">
                      <v-card-text class="py-1">
                        <v-form :ref="`filterForm_${index}`" lazy-validation>
                          <v-row no-gutters v-for="(filteList,index) in item.gird_filter_data.filter" :key="index">
                            <v-col cols="6" class="py-1">
                              <v-select :rules="filterRules" :class="{'data-grid-filter-field': filteList.searchField}" dense v-model="filteList.searchField" @change="removeFilters = true" :items="item.default_value.grid_data.newLable.filter(y => !item.gird_filter_data.filter.map(z => (z.searchField)).includes(y.label))" :placeholder="filteList.searchField ? filteList.searchField : 'Field'"
                                  :item-text="(label) => label && label.field ? label.label : false" :item-value="(label) => label && label.field && label.field.type !== 'label' ? label.label : ''" hide-details="auto" outlined>
                                </v-select>
                            </v-col>
                            <v-col cols="5" class="py-1">
                              <v-text-field :rules="filterRules" label="Value" v-model="filteList.searchValue" dense hide-details="auto" outlined></v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-center mt-2">
                              <v-btn fab x-small @click="removeFilter(item.gird_filter_data.filter, index)" class="error rounded-xl" elevation="0">
                                <v-icon small dark>mdi-minus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                      <v-btn v-if="item.default_value.grid_data.labels.filter(x => x.field.type !== 'label').filter(y => !item.gird_filter_data.filter.map(z => z.searchField).includes(y.label)).length" class="my-2 mx-2" fab dark x-small color="green" @click="addnewFiler(item, index)">
                        <v-icon dark small>mdi-plus</v-icon>
                      </v-btn>
                      <v-divider></v-divider>
                      <v-card-actions class="mx-auto">
                        <v-row>
                          <v-col cols="1" class="py-0">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="float-start" color="grey darken-3" dark v-bind="attrs" v-on="on">mdi-information</v-icon>
                              </template>
                              <ol>
                                <li>{{ $t('message.common.filterSaveResult') }}</li>
                                <li>{{ $t('message.common.datagrid_filter_help_text_1') }}</li>
                                <li>{{ $t('message.common.datagrid_filter_help_text_2') }}</li>
                                <li>{{ $t('message.common.datagrid_filter_help_text_3') }}</li>
                                <li>{{ $t('message.common.datagrid_filter_help_text_4') }}</li>
                                <li>{{ $t('message.common.datagrid_filter_help_text_5') }}</li>
                                <li>{{ $t('message.common.datagrid_filter_help_text_6') }}</li>
                                <li>{{ $t('message.common.datagrid_filter_help_text_7') }}</li>
                              </ol>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="11" class="text-center float-center align-center justify-center py-0">
                            <v-btn color="info" @click="searchFilter(item, index, false)">{{ $t('message.checklist.filter') }}</v-btn>
                            <v-btn color="error lighten-2" class="mx-2" @click="clearDataGridFilter(item, index)">{{ $t('message.shifts.clear') }}</v-btn>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
            <v-simple-table :key="key" class="bordered--table datagrid-18-table" v-if="item.default_value && item.default_value.grid_data && item.default_value.grid_data.labels"> <!-- remove false if using this table -->
              <thead>
                <tr>
                  <th :style="`height:34px; min-width:${grid.pixels}px`" v-for="(grid, index) in item.default_value.grid_data.labels" :key="index">{{ grid.label }}</th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr ref="dataGrid" v-for="(trData, trIndex, rowIndexValue) in item.default_value.grid_data.filterData" :key="trIndex" :draggable="true" @dragstart="handleDrag($event, trData, trIndex, index, rowIndexValue, item.default_value.grid_data.filterData)" @dragover.prevent @drop="handleDrop($event, trData, trIndex, index, rowIndexValue, item.default_value.grid_data.filterData)">
                  <td v-for="(tdGrid, tdIndex) in item.default_value.grid_data.labels" :key="tdIndex">
                    <template v-if="trData[tdIndex] && (trData[tdIndex].conditionscript ? trData[tdIndex].isShowElement ? trData[tdIndex].isShowElement(trData[tdIndex], self) : () => {} : trData[tdIndex].isShow)">
                      <template v-if="tdGrid.field.type === fieldTypeConstants.STRING || tdGrid.field.type === fieldTypeConstants.NUMBER">
                        <v-text-field @change="checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex)" :id="`_${tdGrid.name}_${trIndex}`" v-model="trData[tdIndex].value" :placeholder="tdGrid.field.type === fieldTypeConstants.STRING ? 'Text' : 'Number'" dense hide-details :type="tdGrid.field.type === fieldTypeConstants.NUMBER ? 'number' : 'text'"></v-text-field>
                      </template>
                      <template v-if="tdGrid.field.type === fieldTypeConstants.TEXTAREA">
                        <v-textarea rows="3" @change="checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex)" :id="`_${tdGrid.name}_${trIndex}`" v-model="trData[tdIndex].value" placeholder="Textarea" dense hide-details></v-textarea>
                      </template>
                      <template v-if="tdGrid.field.type === fieldTypeConstants.CHECKBOX && tdGrid.field.typedata">
                        <v-layout wrap class="col" v-if="tdGrid.field.typedata.listItems">
                          <v-checkbox @change="checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex, item, tdIndex)" :id="`_${tdGrid.name}_${trIndex}`" v-for="(options, index) in tdGrid.field.typedata.listItems" :key="index" v-model="trData[tdIndex].value" class="mt-0  pa-1 mx-1" hide-details :label="options.label" :value="options.value" multiple></v-checkbox>
                        </v-layout>
                      </template>
                      <template v-if="tdGrid.field.type === fieldTypeConstants.RADIOBOX && tdGrid.field.typedata">
                        <v-row class="mx-0" v-if="tdGrid.field.typedata.listItems">
                          <v-radio-group @change="checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex, item, tdIndex)" :id="`_${tdGrid.name}_${trIndex}`" class="mt-0" row hide-details v-model="trData[tdIndex].value">
                            <v-radio v-for="(options, index) in tdGrid.field.typedata.listItems" :key="index" :label="options.label" :value="options.value" @click="handleRadioButtonClickforRadio(item, trData, trIndex, tdGrid, tdIndex, options)"></v-radio>
                          </v-radio-group>
                        </v-row>
                      </template>
                      <template v-if="tdGrid.field.type === fieldTypeConstants.SELECT && tdGrid.field.typedata">
                        <v-autocomplete v-if="tdGrid.field.typedata.selectbox_type !== undefined ? tdGrid.field.typedata.selectbox_type === 'default' : true" :id="`_${tdGrid.name}_${trIndex}`" dense :items="tdGrid.field.typedata.listItems"
                        :multiple="tdGrid.field.typedata.isMultiSelect" clearable hide-details item-text="label" item-value="value" v-model="trData[tdIndex].value"
                        @change="checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex)">
                        <template v-slot:selection="{ item, index }">
                          <span v-if="index === 0">{{ item.label }}</span>
                          <span v-if="index === 1" class="grey--text caption">(+{{ trData[tdIndex].value.length - 1 }} {{ $t('message.common.others') }})</span> -->
                        <template v-for="(trData, trIndex, rowIndexValue) in item.default_value.grid_data.filterData">
                        <tr v-if="trData" :ref="`dataGrid_${item.name}`"  :key="trIndex" :draggable="true" @dragstart="handleDrag($event, trData, trIndex, index, rowIndexValue, item.default_value.grid_data.filterData)" @dragover.prevent @drop="handleDrop($event, trData, trIndex, index, rowIndexValue, item.default_value.grid_data.filterData)">
                          <td v-for="(tdGrid, tdIndex) in item.default_value.grid_data.labels" :key="tdIndex">
                            <template v-if="trData[tdIndex] && ((trData[tdIndex].conditionscript && trData[tdIndex].isShowElement) ? trData[tdIndex].isShowElement ? trData[tdIndex].isShowElement(trData[tdIndex], self) : () => {} : trData[tdIndex].isShow)">
                              <template v-if="tdGrid.field.type === fieldTypeConstants.STRING || tdGrid.field.type === fieldTypeConstants.NUMBER">
                                <v-text-field  @change="checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex)" :id="`_${tdGrid.name}_${trIndex}`" v-model="trData[tdIndex].value" :placeholder="tdGrid.field.type === fieldTypeConstants.STRING ? 'Text' : 'Number'" dense hide-details :type="tdGrid.field.type === fieldTypeConstants.NUMBER ? 'number' : 'text'"></v-text-field>
                        </template>
                        <!-- </v-autocomplete>
                        <v-autocomplete v-else-if="tdGrid.field.typedata.selectbox_type === 'custom'" :id="`_${tdGrid.name}_${trIndex}`" dense :item-text="tdGrid.field.typedata.optionName" :item-value="tdGrid.field.typedata.optionValue"
                        :items="tdGrid.field.typedata.customOptions" :multiple="tdGrid.field.typedata.isMultiSelect" clearable hide-details v-model="trData[tdIndex].value"
                        @change="checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex)">
                        <template v-slot:selection="{ item, index }">
                          <span v-if="index === 0">{{ item[tdGrid.field.typedata.optionName] }}</span>
                          <span v-if="index === 1" class="grey--text caption">(+{{ trData[tdIndex].value.length - 1 }} {{ $t('message.common.others') }})</span> -->
                          <template v-if="tdGrid.field.type === fieldTypeConstants.TEXTAREA">
                            <v-textarea rows="3" @change="checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex)" :id="`_${tdGrid.name}_${trIndex}`" v-model="trData[tdIndex].value" placeholder="Textarea" dense hide-details></v-textarea>
                        </template>
                        <!-- </v-autocomplete>
                      </template>
                      <template v-if="tdGrid.field.type === fieldTypeConstants.LABEL">
                        <label v-if="tdGrid.field.typedata">
                          <span v-if="tdGrid.field.typedata.calculatingValue && tdGrid.field.typedata.insertPosition === 'prefix'">{{+tdGrid.field.typedata.calculatingValue * (+rowIndexValue + 1)}}</span>{{tdGrid.field.typedata.labeltext}}<span v-if="tdGrid.field.typedata.calculatingValue && tdGrid.field.typedata.insertPosition === 'suffix'">{{+tdGrid.field.typedata.calculatingValue * (+rowIndexValue + 1)}}</span>
                        </label>
                      </template>
                      <template v-if="tdGrid.field.type === fieldTypeConstants.DATE">
                        <v-menu v-model="trData[tdIndex].date_menu" :close-on-content-click="false" max-width="290">
                          <template v-slot:activator="{ on }">
                            <v-text-field readonly :id="`_${tdGrid.name}_${trIndex}`" dense hide-details v-model="trData[tdIndex].value" clearable prepend-icon="mdi-calendar" v-on="on" @click:clear="trData[tdIndex].value = null"></v-text-field> -->
                          <template v-if="tdGrid.field.type === fieldTypeConstants.CHECKBOX && tdGrid.field.typedata">
                              <v-layout wrap class="col" v-if="tdGrid.field.typedata.listItems">
                                <v-checkbox @change="checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex, item, tdIndex)" :id="`_${tdGrid.name}_${trIndex}`" v-for="(options, index) in tdGrid.field.typedata.listItems" :key="index" v-model="trData[tdIndex].value" class="mt-0  pa-1 mx-1" hide-details :label="options.label" :value="options.value" multiple></v-checkbox>
                              </v-layout>
                            </template>
                            <template v-if="tdGrid.field.type === fieldTypeConstants.RADIOBOX && tdGrid.field.typedata">
                              <v-row class="mx-0" v-if="tdGrid.field.typedata.listItems">
                                <v-radio-group @change="checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex, item, tdIndex)" :id="`_${tdGrid.name}_${trIndex}`" class="mt-0" row hide-details v-model="trData[tdIndex].value">
                                  <v-radio v-for="(options, index) in tdGrid.field.typedata.listItems" :key="index" :label="options.label" :value="options.value" @click="handleRadioButtonClickforRadio(item, trData, trIndex, tdGrid, tdIndex, options)"></v-radio>
                                </v-radio-group>
                              </v-row>
                            </template>
                            <template v-if="tdGrid.field.type === fieldTypeConstants.SELECT && tdGrid.field.typedata">
                              <v-autocomplete v-if="tdGrid.field.typedata.selectbox_type !== undefined ? tdGrid.field.typedata.selectbox_type === 'default' : true" :id="`_${tdGrid.name}_${trIndex}`" dense :items="tdGrid.field.typedata.listItems"
                              :multiple="tdGrid.field.typedata.isMultiSelect" clearable hide-details item-text="label" item-value="value" v-model="trData[tdIndex].value"
                              @change="checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex)">
                              <template v-slot:selection="{ item, index }">
                                <span v-if="index === 0">{{ item.label }}</span>
                                <span v-if="index === 1" class="grey--text caption">(+{{ trData[tdIndex].value.length - 1 }} {{ $t('message.common.others') }})</span>
                          </template>
                          <!-- <v-date-picker @change="triggerDatePickerChangeEvent(`_${tdGrid.name}_${trIndex}`);checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex)" v-model="trData[tdIndex].date_value" @input="trData[tdIndex].value = parseDate(trData[tdIndex].date_value), trData[tdIndex].date_menu = false"></v-date-picker>
                        </v-menu> -->
                        </v-autocomplete>
                              <v-autocomplete v-else-if="tdGrid.field.typedata.selectbox_type === 'custom'" :id="`_${tdGrid.name}_${trIndex}`" dense :item-text="tdGrid.field.typedata.optionName" :item-value="tdGrid.field.typedata.optionValue"
                              :items="tdGrid.field.typedata.customOptions" :multiple="tdGrid.field.typedata.isMultiSelect" clearable hide-details v-model="trData[tdIndex].value"
                              @change="checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex)">
                              <template v-slot:selection="{ item, index }">
                                <span v-if="index === 0">{{ item[tdGrid.field.typedata.optionName] }}</span>
                                <span v-if="index === 1" class="grey--text caption">(+{{ trData[tdIndex].value.length - 1 }} {{ $t('message.common.others') }})</span>
                              </template>
                              </v-autocomplete>
                            </template>
                            <template v-if="tdGrid.field.type === fieldTypeConstants.LABEL">
                              <label v-if="tdGrid.field.typedata">
                                <span v-if="tdGrid.field.typedata.calculatingValue && tdGrid.field.typedata.insertPosition === 'prefix'">{{+tdGrid.field.typedata.calculatingValue * (+rowIndexValue + 1)}}</span>{{tdGrid.field.typedata.labeltext}}<span v-if="tdGrid.field.typedata.calculatingValue && tdGrid.field.typedata.insertPosition === 'suffix'">{{+tdGrid.field.typedata.calculatingValue * (+rowIndexValue + 1)}}</span>
                              </label>
                            </template>
                            <template v-if="tdGrid.field.type === fieldTypeConstants.DATE">
                              <v-menu v-model="trData[tdIndex].date_menu" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on }">
                                  <v-text-field readonly :id="`_${tdGrid.name}_${trIndex}`" dense hide-details v-model="trData[tdIndex].value" clearable prepend-icon="mdi-calendar" v-on="on" @click:clear="trData[tdIndex].value = null"></v-text-field>
                                </template>
                                <v-date-picker @change="triggerDatePickerChangeEvent(`_${tdGrid.name}_${trIndex}`);checkShowHideFunctionOnChange(trData[tdIndex], trData[tdIndex].value, trIndex)" v-model="trData[tdIndex].date_value" @input="trData[tdIndex].value = parseDate(trData[tdIndex].date_value), trData[tdIndex].date_menu = false" color="primary darken-2" header-color="primary"></v-date-picker>
                              </v-menu>
                            </template>
                      </template>
                    <!-- </template>
                  </td>
                  <td>
                    <v-btn fab x-small @click="removeGrid(item.default_value.grid_data.filterData, trData, trIndex, rowIndexValue, item.default_value.grid_data, index)" class="error rounded-xl" elevation="0">
                      <v-icon small dark>mdi-minus</v-icon>
                    </v-btn>
                  </td>
                </tr> -->
                  </td>
                        <td>
                          <v-btn fab x-small @click="removeGrid(item.default_value.grid_data.filterData, trData, trIndex, rowIndexValue, item.default_value.grid_data,index)" class="error rounded-xl" elevation="0" :title="$t('message.common.delete_row')">
                            <v-icon small dark>mdi-minus</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                <tr style="background: lightgrey" v-if="item.showSum.showSumRow && !$formatter.isEmptyObject(item.default_value.grid_data.data)">
                  <!-- class="text-right" --><th style="height:30px" v-for="(sumRow, sumIndex) in item.default_value.grid_data.labels" :key="sumIndex">
                    <span v-if="item.showSum.sumColIndexes.includes(sumIndex)" style="font-size: 0.9rem;">{{ getGridDataValuesTotal(sumIndex, item.default_value.grid_data.data) }}</span><!--  | dotWithComma -->
                  </th>
                </tr>
              </tbody>
            </v-simple-table>
            <v-btn class="mt-2" fab dark x-small color="primary" @click="addnewLineOnGrid(index, item.label, item)">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <template v-if="item.type === 19">
            <v-card class="component-link" outlined v-if="item.html">
              <v-card-text class="pa-2">
                <p v-html="item.html"></p>
              </v-card-text>
            </v-card>
            <v-card class="d-flex flex-row" flat>
              <v-card flat outlined width="100%">
                <v-row class="mx-0" no-gutters>
                  <v-layout class="cols" style="align-items:center">
                    <v-spacer></v-spacer>
                    <!-- <v-checkbox v-if="item.hideCommentsConst" v-model="fields[item.name].showComment" @change="item.default_value.hideComments = !fields[item.name].showComment" class="pa-0 ma-0" hide-details off-icon="mdi-comment-remove-outline" on-icon="mdi-comment-check"></v-checkbox>
                    <v-checkbox v-if="item.hideAttachmentsConst" v-model="fields[item.name].showAttachment" @change="item.default_value.hideAttachments = !fields[item.name].showAttachment" class="pa-0 ma-0" hide-details off-icon="mdi-link-off" on-icon="mdi-attachment"></v-checkbox> -->
                    <v-btn :disabled="(item.default_value.hideComments && item.default_value.hideAttachments) || hasCommentOrAttachment(fields[item.name], item)" x-small fab text color="primary" @click="item.expandsurveysection = !item.expandsurveysection">
                      <v-icon>{{ `${ item.expandsurveysection ? 'mdi-arrow-up' : 'mdi-arrow-down'}`}}</v-icon>
                    </v-btn>
                  </v-layout>
                </v-row>
                <v-row class="mx-0">
                  <v-col class="pa-0" cols="12">
                    <v-card-title class="pt-0 pa-1 subtitle-1">{{ item.label }}</v-card-title>
                  </v-col>
                  <v-col class="pa-0 mb-2" cols="12">
                    <template v-if="item.default_value.surveyOptions && item.default_value.surveyOptions.selectionType === 1">
                      <v-layout row wrap class="mx-0" v-if="fields[item.name]">
                        <v-checkbox v-model="fields[item.name].answer" :label="option.label" :value="option.value" v-for="(option, index) in (item.default_value.options.filter( x => x.isactive).filter( x => x.isactive))" :key="index" multiple></v-checkbox>
                      </v-layout>
                    </template>
                    <template v-else>
                      <v-radio-group v-model="fields[item.name].answer" class="pa-0" hide-details row :id="`_${item.name}`">
                        <v-radio class="pa-0 ma-0" :label="options.label" :value="options.value" v-for="(options, index) in getActiveOptions(item.default_value)" :key="index" @click="handleRadioButtonClick(item, options, fields[item.name].answer, 'answer')"></v-radio>
                      </v-radio-group>
                    </template>
                  </v-col>
                  <v-col class="pa-1 my-1" cols="12" v-if="item.default_value.checkboxFlags.signature && !item.fieldsToHide.includes('signature')">
                    <v-text-field :id="`_${item.name}_signature`" outlined hide-details v-model="fields[item.name].signature" :label="$t('message.mixed.signature')" dense></v-text-field>
                  </v-col>
                  <v-col class="pa-1 my-1" cols="12" v-if="item.default_value.checkboxFlags.date && !item.fieldsToHide.includes('date')">
                    <v-menu v-model="fields[item.name].date_menu" :close-on-content-click="false" max-width="290">
                      <template v-slot:activator="{ on }">
                        <v-text-field dense outlined hide-details v-model="fields[item.name].date" clearable prepend-inner-icon="mdi-calendar" :label="$t('message.common.date')" v-on="on" @click:clear="fields[item.name].date = null"></v-text-field>
                      </template>
                      <v-date-picker v-model="fields[item.name].date_value" @input="fields[item.name].date_menu = false, fields[item.name].date = parseDate(fields[item.name].date_value)"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <!-- <v-divider class="ma-0 mt-2" v-show="(((!item.default_value.hideComments && !item.fieldsToHide.includes('comments')) || (!item.default_value.hideAttachments && !item.fieldsToHide.includes('attachments'))) && item.expandsurveysection) || item.html"></v-divider> -->
                <v-expand-transition>
                  <v-card v-if="item.expandsurveysection">
                    <v-card-text class="pa-0" v-show="((!item.default_value.hideComments && !item.fieldsToHide.includes('comments')) || (!item.default_value.hideAttachments && !item.fieldsToHide.includes('attachments'))) || item.html">
                      <v-tabs>
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab class="pa-0" v-if="(!item.default_value.hideComments && !item.fieldsToHide.includes('comments'))">
                          <v-icon>mdi-comment-text-outline</v-icon>
                          {{ $t('message.common.comments') }}
                        </v-tab>
                        <v-tab class="pa-0" v-if="(!item.default_value.hideAttachments && !item.fieldsToHide.includes('attachments'))">
                          <v-icon class="mdi-rotate-270">mdi-attachment</v-icon>
                          {{ $t('message.mixed.attachments') }}
                          <v-avatar left class="primary ml-2" for size="25"><span class="white--text" v-if="listOfDocuments[item.name]">{{ (listOfDocuments[item.name].length) }}</span><span v-else class="white--text">0</span></v-avatar>
                        </v-tab>
                        <v-tab-item v-if="(!item.default_value.hideComments && !item.fieldsToHide.includes('comments'))">
                          <v-divider class="ma-0 pa-0"></v-divider>
                          <v-card flat tile>
                            <v-card-text>
                              <v-textarea hide-details outlined v-model="fields[item.name]['comments']"></v-textarea>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item v-if="(!item.default_value.hideAttachments && !item.fieldsToHide.includes('attachments'))">
                          <v-divider class="ma-0 pa-0"></v-divider>
                          <v-card flat tile :key="reRender">
                            <v-card-text>
                              <v-row class="mx-0 pa-0">
                                <v-col cols="12" md="6" class="pa-0">
                                  <input v-if="isIos" type="file" prepend-icon="mdi-camera" @change="handleFileUpload($event, item._id, item.name)" :label="item.label" multiple class="mx-1" />
                                  <v-file-input v-else :label="$t('message.shifts.documents')" hide-details multiple @change="uploadDocumentHandler(item._id, item.name, fields[item.name]['attachments'])" v-model="fields[item.name]['attachments']" outlined dense></v-file-input>
                                </v-col>
                              </v-row>
                              <v-row class="mx-0 ml-8" :key="reRender">
                                <v-col cols="12" class="pa-0" v-for="(document, docIndex) in listOfDocuments[item.name]" :key="document._id">
                                  <v-layout>
                                    <p class="pa-0 ma-0 my-auto mr-3">{{document.file_original_name}}</p>
                                    <v-spacer></v-spacer>
                                    <v-btn icon color="primary" @click="editDocument(document, item.name)">
                                      <v-scale-transition>
                                        <v-icon>mdi-lead-pencil</v-icon>
                                      </v-scale-transition>
                                    </v-btn>
                                    <v-btn icon color="info" @click="downloadDocument(document._id)">
                                      <v-scale-transition>
                                        <v-icon>mdi-cloud-download</v-icon>
                                      </v-scale-transition>
                                    </v-btn>
                                    <v-btn icon color="error" @click="deleteDocument(document._id, item.name)">
                                      <v-scale-transition>
                                        <v-icon>mdi-delete-circle</v-icon>
                                      </v-scale-transition>
                                    </v-btn>
                                  </v-layout>
                                  <v-divider v-if="listOfDocuments[item.name].length > (docIndex + 1)"></v-divider>
                                </v-col>
                              </v-row>
                              <v-row no-gutters v-if="false">
                                <v-col cols="12" lg="1" md="2" sm="3" v-for="(document) in listOfDocuments[item.name]" :key="document._id" class="pa-0 pl-2 mt-1">
                                  <v-hover v-slot:default="{ hover }">
                                    <v-card :elevation="hover ? 14 : 1" shaped flat>
                                      <v-card-text class="text-center pa-1">
                                        <template v-if="mimeTypeOfAttachment(document).url">
                                          <v-img contain height="50" :lazy-src="mimeTypeOfAttachment(document).url" :src="mimeTypeOfAttachment(document).url"></v-img>
                                        </template>
                                        <v-avatar size="52" class="profile" v-else>
                                          <v-icon :size="50" :color="mimeTypeOfAttachment(document).color">{{ mimeTypeOfAttachment(document).icon }}</v-icon>
                                        </v-avatar>
                                      </v-card-text>
                                      <v-card-subtitle class="pa-1 text-center text-truncate">
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <span v-on="on" class="font-weight-bold">{{ document.file_original_name }}</span>
                                          </template>
                                          <span>{{ document.file_original_name }}</span>
                                        </v-tooltip>
                                      </v-card-subtitle>
                                      <v-card-actions class="pa-0">
                                        <v-spacer></v-spacer>
                                        <v-btn small icon color="primary" @click="editDocument(document, item.name)">
                                          <v-scale-transition>
                                            <v-icon v-if="hover">mdi-lead-pencil</v-icon>
                                          </v-scale-transition>
                                        </v-btn>
                                        <v-btn small icon color="info" @click="downloadDocument(document.file_original_name)">
                                          <v-scale-transition>
                                            <v-icon v-if="hover">mdi-cloud-download</v-icon>
                                          </v-scale-transition>
                                        </v-btn>
                                        <v-btn small icon color="error" @click="deleteDocument(document._id, item.name)">
                                          <v-scale-transition>
                                            <v-icon v-if="hover">mdi-delete-circle</v-icon>
                                          </v-scale-transition>
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-hover>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </v-card-text>
                  </v-card>
                </v-expand-transition>
              </v-card>
            </v-card>
          </template>
        </v-col>
      </template>
      <v-dialog v-model="updateFileDialog" width="500">
        <v-card>
          <v-form ref="updateAttachment">
            <v-card-title class="headline grey lighten-2 pa-2" primary-title>{{$t('message.common.update')}} {{$t('message.deviation.document')}}</v-card-title>
            <v-card-text class="mt-2">
              <p>{{$t('message.deviation.document')}}: <b>{{ updateDocument.file_original_name }}</b></p>
              <v-file-input class="mt-2" v-model="updateFilePicker" label="Document" outlined dense></v-file-input>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="updateDocumentHandler" :loading="fileLoader">{{$t('message.common.update')}}</v-btn>
              <v-btn color="error" @click="updateFileDialog = false, $refs.updateAttachment.reset()">{{$t('message.common.close')}}</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
</template>
<script>
import { componentStatusList } from '../../utils/support_list'
// import { VueEditor } from 'vue2-editor'
// eslint-disable-next-line camelcase
import { checklistApi, documentUrl, hostAppApi } from '@/plugins/axios_settings'
import dataGridComponent from '@/mixins/data_grid_component'
import surveyAndAdvancedSurvey from '@/mixins/survey_and_advanced_survey'
import lodash from 'lodash'
import moment from 'moment'
export default {
  mixins: [dataGridComponent, surveyAndAdvancedSurvey],
  props: ['aliasFieldsList', 'fields', 'checklistId', 'listOfDocuments', 'dataGridImportValue'],
  data () {
    return {
      reRender: 0,
      autoCompleteRender: 0,
      deleteDocumentId: 0,
      deleteDocumentName: '',
      statusList: componentStatusList,
      updateFileDialog: false,
      updateDocument: {},
      updateFilePicker: [],
      updateDocumentProp: '',
      fileLoader: false,
      // aliasFieldsList: [],
      fieldsData: {},
      // listOfFields: [],
      // listOfDocuments: {},
      loading: false,
      apiLoading: false,
      checklistSetData: {
        isProject: this.module === 'project' || Boolean(parseInt(this.$route.params.project_id || 0)),
        project: this.projectChecklistFormData
      },
      escapedCloseBracket: this.escapeRegExp(')'),
      isIos: false,
      radioLastCheckHistory: {},
      radioKey: null,
      gridFilter: [
        {
          searchValue: null,
          searchField: null
        }
      ],
      dataGridFilterDataValues: {},
      key: 0,
      allDataGridValues: [],
      dragAndDropList: null,
      removeFilters: false,
      menuTime: false,
      filterRules: [v => !!v || this.$t('message.deviation.required_message')]
    }
  },
  computed: {},
  components: {
    'form-render': () => import('./FormRender.vue')
    // VueEditor
  },
  created () {
    window.addEventListener('resize', this.checkDeviceName)
  },
  destroyed () {
    window.addEventListener('resize', this.checkDeviceName)
  },
  beforeMount () {
    this.$emit('filterGrid', false)
    if (this.aliasFieldsList) {
      this.aliasFieldsList.forEach((x, idx) => {
        if (x.type === 18) {
          x.default_value.grid_data.newLable = []
          x.default_value.grid_data.labels.forEach(val => {
            if (val.field.type !== 'label') {
              x.default_value.grid_data.newLable.push(val)
            }
          })
          var filterIndexObj = {}
          if (this.fields && this.fields.filterGrid) {
            filterIndexObj = this.fields.filterGrid.find(y => y.index === idx)
            filterIndexObj = (filterIndexObj || filterIndexObj) ? filterIndexObj : { index: idx, value: [{ searchField: null, searchValue: null }] }
          } else {
            filterIndexObj = { index: idx, value: [{ searchField: null, searchValue: null }] }
          }
          x.gird_filter_data.filter = filterIndexObj && filterIndexObj.value ? this.$formatter.cloneVariable(filterIndexObj.value) : {}
          x.default_value.grid_data.filterData = x.default_value.grid_data.data
          this.searchFilter(x, idx, true)
        }
      })
    }
    // this.aliasFieldsList.forEach(x => {
    //   if (x.default_value && x.default_value.grid_data && x.default_value.grid_data.labels) {
    //     x.default_value.grid_data.labels = x.default_value.grid_data.labels.sort((a, b) => a.position - b.position)
    //   }
    // })
  },
  mounted () {
    // if (this.checklistId == null) this.saveUpdateChecklistHandler()
    // if (this.checklistSetData.project && Object.prototype.hasOwnProperty.call(this.checklistSetData.project, 'datagrid')) delete this.projectChecklistFormData.datagrid
    // if (!this.panelForm) {
    //   this.getFormProperties()
    // }
    // this.$root.$on('callRerender', () => {
    //   this.reRender++
    // })
    // // if (!this.checklistId) this.saveUpdateChecklistHandler(false)
    // this.getProjects = this.$formatter.debounceWithPromiseReturn(this.debouncedGetProjects, 200)
    // const userAgent = window.navigator.userAgent.toLowerCase() || navigator.userAgent.toLowerCase() || navigator.vendor.toLowerCase() || navigator.platform.toLowerCase()
    // this.isIos = (/iphone|ipad|ipod/.test(userAgent)) || (/macintosh/.test(userAgent) && 'ontouchend' in document)
  },
  methods: {
    escapeRegExp (str) {
      // eslint-disable-next-line
      return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    },
    initWithValues () {
      this.manipulateFields()
      this.manipulateFieldsData()
    },
    getValidation (validate, type = null) {
      switch (validate) {
        case 'required':
          if (type) return this.$_multiSelectValidation
          else return this.$_requiredValidation
        default:
          return null
      }
    },
    parseDate (date) {
      return this.$formatter.formatDate(date, 'YYYY-MM-DD', 'DD.MM.YYYY')
    },
    getValuesFromAPI (searchText, value, index) {
      let options = []
      this.$execute.execute('get', value.apiUrl, { headers: '' }).then(response => {
        if (value.objectName) options = response.data[value.objectName]
        else options = response.data
        const fieldItem = this.aliasFieldsList[index]
        fieldItem.default_value.options = []
        fieldItem.default_value.options = options
        this.$set(this.aliasFieldsList, index, { ...fieldItem })
      })
    },
    uploadDocumentHandler (propId, propName, files) {
      this.fields[propName].attachments = null
      var formData = new FormData()
      if (files != null && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append(files[i].name, files[i])
        }
        checklistApi.post(`documents/upload?checklist_id=${this.checklistId}&property_id=${propId}`, formData, this.token)
          .then((response) => {
            if (response.data) {
              this.listOfDocuments[propName].push(...response.data)
              if (this.listOfDocuments[propName] && this.listOfDocuments[propName].length > 0) {
                this.listOfDocuments[propName].forEach(element => {
                  element.imgObj = this.mimeTypeOfAttachment(element)
                })
              }
              this.reRender++
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.updatedSuccess' })
            }
          })
      }
    },
    updateDocumentHandler () {
      if (this.updateFilePicker) {
        const formData = new FormData()
        formData.append(this.updateFilePicker.name, this.updateFilePicker)
        this.fileLoader = true
        checklistApi.post(`documents/update_attachment/${this.updateDocument._id}`, formData, this.token)
          .then((response) => {
            if (response.data) {
              const documents = this.$formatter.cloneVariable(this.listOfDocuments[this.updateDocumentProp])
              if (this.$formatter.isArrayHasData(documents)) {
                const index = documents.findIndex(x => x._id === this.updateDocument._id)
                documents.splice(index, 1, response.data[0])
                this.listOfDocuments[this.updateDocumentProp] = documents
                if (this.listOfDocuments[this.updateDocumentProp] && this.listOfDocuments[this.updateDocumentProp].length > 0) {
                  this.listOfDocuments[this.updateDocumentProp].forEach(element => {
                    element.imgObj = this.mimeTypeOfAttachment(element)
                  })
                }
                this.reRender++
              }
            }
          })
          .finally(() => {
            this.$refs.updateAttachment.reset()
            this.fileLoader = false
            this.updateFileDialog = false
          })
      }
    },
    getFieldsData () {
      checklistApi.get(`/checklists/${this.checklistId}`, this.token)
        .then((result) => {
          if (result && result.data) {
            this.fieldsData = result.data
          }
        })
        .finally(() => {
          this.initWithValues()
        })
    },
    getDocumentsHandler (propName = null) {
      checklistApi.get(`/documents/get_checklist_documents/${this.checklistId}`, this.token)
        .then((response) => {
          if (response && response.data) {
            const result = response.data
            if (result.length > 0) {
              result.forEach(element => {
                element.imgObj = this.mimeTypeOfAttachment(element)
              })
            }
            setTimeout(() => {
              const getDocuments = result ? this.$formatter.groupBy(result, 'property_name') : {}
              this.listOfDocuments = Object.assign({ ...this.listOfDocuments }, { ...getDocuments })
              if (propName) this.listOfDocuments[propName] = getDocuments[propName] || []
              this.reRender = this.reRender + 1
            }, 200)
          }
        })
    },
    clearThisSignature (id) {
      const self = this
      const reference = `signaturePad_${id}`
      setTimeout(() => {
        self.$refs[reference][0].clearSignature()
      }, 100)
    },
    downloadDocument (id) {
      checklistApi.get(`documents/download/${id}`, this.token).then(response => {
        const filename = response.data
        window.open(`${checklistApi.defaults.baseURL}files/download?fileName=${filename}`, '_blank')
      })
    },
    editDocument (document, propName) {
      this.updateFileDialog = true
      this.updateDocument = document
      this.updateDocumentProp = propName
    },
    deleteDocument (id, propName, index) {
      checklistApi.delete(`/documents/${id}`, this.token)
        .then(response => {
          this.getDocumentsHandler(propName)
        })
    },
    closeHandler () {
      this.$emit('back')
    },
    addnewLineOnGrid (index, size, item) {
      if (item.name === this.aliasFieldsList[index].name) {
        var findLastIndexDatageid = this.aliasFieldsList.findLastIndex(gridtype => gridtype.type === 18)
        var lastGrid = Object.keys(this.aliasFieldsList[findLastIndexDatageid].default_value.grid_data.data)
        var lastGridFilter = Object.keys(this.aliasFieldsList[findLastIndexDatageid].default_value.grid_data.filterData)
        if (lastGrid.length) {
          lastGrid.forEach(key => {
            if (lastGridFilter.includes(key) && this.aliasFieldsList[findLastIndexDatageid].default_value.grid_data.filterData[key]) {
              this.aliasFieldsList[findLastIndexDatageid].default_value.grid_data.data[key] = this.aliasFieldsList[findLastIndexDatageid].default_value.grid_data.filterData[key]
            }
          })
        }
        var addLength = Object.keys(this.aliasFieldsList[index].default_value.grid_data.data).length
        if (addLength > 0) {
          var rowIds = []
          const existingRows = Object.keys(this.aliasFieldsList[index].default_value.grid_data.data)
          // existingRows.forEach(val => rowIds.push(Number(val.split('_')[1])))
          existingRows.forEach(val => (val && val.split('_')[1]) ? rowIds.push(Number(val.split('_')[1])) : rowIds.push(0))
          addLength = Number(Math.max(...rowIds)) + 1
        } else {
          addLength = 1
        }
        const getGrid = this.aliasFieldsList[index].default_value.grid_data.data
        const labels = this.aliasFieldsList[index].default_value.grid_data.labels
        const gridData = []
        for (let i = 0; i < size; i++) {
          let value = ''
          if (labels[i].field.type === this.fieldTypeConstants.DATE) {
            const defaultValueKeyword = lodash.get(labels[i], 'field.typedata.dateDefaultValueKeyword')
            if (defaultValueKeyword && defaultValueKeyword === 'CURDATE') value = moment().format('DD.MM.YYYY')
          } else {
            let defauleValue = lodash.get(labels[i], 'field.typedata.default_value')
            if (labels[i].field.type === this.fieldTypeConstants.CHECKBOX) defauleValue = defauleValue ? [defauleValue] : []
            if (defauleValue) value = defauleValue
          }
          gridData.push({ value })
        }
        // var newkey = `row_${addLength}`
        this.checkAndSetEventHandlers(`row_${addLength}`)
        const resultGrid = Object.assign({ ...getGrid }, { [`row_${addLength}`]: [...gridData] })
        this.aliasFieldsList[index].default_value.grid_data.data = resultGrid
        this.checkAndAddConditionScripts(`row_${addLength}`, this.aliasFieldsList[index].default_value.grid_data.data)
        // this.aliasFieldsList[index].default_value.grid_data.filterData = this.aliasFieldsList[index].default_value.grid_data.data
        // this.dragAndDropList = this.aliasFieldsList[index].default_value.grid_data.filterData
        var dropValue = this.fields.dragAndDrop && Array.isArray(this.fields.dragAndDrop) ? this.$formatter.cloneVariable(this.fields.dragAndDrop).find(x => x.mainindex === index) : null
        if (dropValue && dropValue.dataGridObj && Object.keys(dropValue.dataGridObj) && Object.keys(dropValue.dataGridObj).length > 0) {
          var dataGridObjFilter = {}
          var dragKes = Object.keys(dropValue.dataGridObj)
          dragKes.forEach(key => {
            dataGridObjFilter[key] = this.aliasFieldsList[index].default_value.grid_data.data[key]
          })
          var allKeys = Object.keys(this.aliasFieldsList[index].default_value.grid_data.data)
          allKeys.forEach(key => {
            if (!dragKes.includes(key)) dataGridObjFilter[key] = this.aliasFieldsList[index].default_value.grid_data.data[key]
          })
          // dataGridObjFilter[newkey] = this.aliasFieldsList[index].default_value.grid_data.data[newkey]
          this.aliasFieldsList[index].default_value.grid_data.filterData = dataGridObjFilter
          this.dragAndDropList = this.aliasFieldsList[index].default_value.grid_data.filterData
        } else {
          this.aliasFieldsList[index].default_value.grid_data.filterData = this.aliasFieldsList[index].default_value.grid_data.data
          this.dragAndDropList = this.aliasFieldsList[index].default_value.grid_data.filterData
        }
        this.key++
        this.removeFilters = false
        setTimeout(() => {
          this.$refs[`dataGrid_${item.name}`].findLast(e => e).querySelector('input').focus()
        }, 100)
      }
    },
    // eslint-disable-next-line camelcase
    mimeTypeOfAttachment ({ file_original_name, file_guid }) {
      let image = ''
      const ext = file_original_name.split('.').pop()
      const extension = ext ? ext.toString().toLowerCase() : ''
      switch (extension) {
        case 'png':
        case 'jpeg':
        case 'jpg':
        case 'gif':
          // eslint-disable-next-line camelcase
          image = { icon: 'mdi-file-image', color: null, url: `${documentUrl}/Documents/${file_guid}?t=${new Date().getTime()}` }
          break
        default:
          image = { icon: 'mdi-file-document', color: null, url: null }
          break
      }
      return image
    },
    /* Copied from weldit web  */
    /* Function for the checkpoint component */
    addFilesHandler (propId, propName, subPropName, files) {
      var formData = new FormData()
      if (files != null && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append(files[i].name, files[i])
        }
        checklistApi.post(`documents/upload?checklist_id=${this.checklistId}&property_id=${propId}`, formData)
          .then((response) => {
            if (response.data) {
              this.listOfDocuments[propName].push(...response.data)
              const ids = response.data.map(x => x._id)
              this.fields[propName][subPropName].filesIds.push(...ids)
              this.saveUpdateChecklistHandler(true, false)
              this.reRender++
            }
          })
      }
      this.fields[propName][subPropName].files = null
    },
    getSubItemFiles (propName, subPropName) {
      const fileIds = this.fields[propName][subPropName].filesIds
      const propFiles = this.$formatter.cloneVariable(this.listOfDocuments[propName])
      return propFiles ? propFiles.filter(item => fileIds.includes(item._id)) : []
    },
    setDateFieldValue (propName, subPropModel) {
      if (this.fields[propName][subPropModel].checkBoxU) {
        this.fields[propName][subPropModel].date = moment().format('DD.MM.YYYY')
        this.fields[propName][subPropModel].date_value = moment().format('YYYY-MM-DD')
        // if (!this.fields[propName][subPropModel].date) {}
      } else {
        this.fields[propName][subPropModel].date = ''
        this.fields[propName][subPropModel].date_value = ''
      }
    },
    checkAndGetSumRowData (labels) {
      const obj = { showSumRow: false, sumColIndexes: [] }
      labels.forEach((label, index) => {
        const { field } = label
        if (field && field.typedata && field.typedata.isshowsum) {
          obj.showSumRow = true
          obj.sumColIndexes.push(index)
        }
      })
      return obj
    },
    addGridFirstRow (size, dataRowValues) {
      const getGrid = dataRowValues
      const gridData = []
      for (let i = 0; i < size; i++) {
        gridData.push({ value: '' })
      }
      return Object.assign({ ...getGrid }, { [`row_${0}`]: [...gridData] })
    },
    getGridDataValuesTotal (sumIndex, data) {
      let total = 0
      const rows = Object.keys(data)
      const allRowValues = []
      rows.forEach(key => {
        var obj = data[key].find((x, y) => y === sumIndex)
        if (obj) allRowValues.push(parseFloat(obj.value || 0))
      })
      total = this.$formatter.getSum(allRowValues)
      return total
    },
    hasCommentOrAttachment (surveyObj, item) {
      let hasData = this.hasComments(surveyObj) || this.hasAttachments(item)
      hasData = Boolean(hasData)
      if (hasData) item.expandsurveysection = true
      return hasData
    },
    hasComments (surveyObj) {
      return surveyObj && surveyObj.comments
    },
    hasAttachments (item) {
      return this.listOfDocuments[item.name] && this.listOfDocuments[item.name].length > 0
    },
    getCols (item) { // for component 19
      const flagObj = item.default_value.checkboxFlags
      return (flagObj.signature || flagObj.date) ? (flagObj.signature && flagObj.date) ? 8 : 10 : 12
    },
    getCheckboxLabelWidth (options) {
      options.label = options.label || ''
      return (options.label.length + 100) > 200 ? 350 : (options.label.length + 100)
    },
    getActiveOptions (defaulValue) {
      if (!defaulValue.options || defaulValue.options.length < 1) return []
      let resultOptions = defaulValue.options.filter(x => x.isactive || x.isactive === undefined)
      resultOptions = resultOptions && resultOptions.length > 0 ? resultOptions : []
      return resultOptions
    },
    eventHandler (evt, index, logic) {
      const element = this.$formatter.cloneVariable(this.aliasFieldsList[index])
      // eslint-disable-next-line
      let logicalFunction = new Function('instance', 'input', 'data', element[`logic_${logic.logicname}`])
      logicalFunction(this, evt.target.value, this.fields)
    },
    // Extension get method
    getProperty (key) {
      return this.fields[key]
    },
    // Extension set method
    setProperty (key, value) {
      this.fields[key] = value
    },
    showAlert (type, message) {
      this.$root.$emit('snackbar', { snackbar: true, color: type, text: message })
    },
    getList (field) {
      const result = this.aliasFieldsList.find(x => x.name === field)
      if (result) {
        const list = result.customOptions ? result.customOptions : []
        return list
      } else return []
    },
    // Extension set select options method
    setOptionList (key, value, itemValue) {
      const foundElementIndex = this.aliasFieldsList.findIndex(x => x.name === key)
      if (foundElementIndex !== -1) {
        const item = this.$formatter.cloneVariable(this.aliasFieldsList[foundElementIndex])
        item.customOptions = value
        item.optionName = itemValue
        item.optionValue = itemValue
        this.$set(this.aliasFieldsList, foundElementIndex, item)
      }
    },
    // Manuppulation for Validation on change
    checkForValidationOrFunction (element, value, index) {
      element.conditionscript = () => {}
      // Conditional hide
      if (element.hideField) {
        const foundElementIndex = this.aliasFieldsList.findIndex(x => x.name === element.hideField)
        // eslint-disable-next-line
        let checkCondition = element.type === 3 ? element.hideConditions.value == value.toString() : element.hideConditions.value === value // Condition for checkbox
        if (checkCondition) {
          this.aliasFieldsList[foundElementIndex].isShow = element.hideConditions.condition
        } else this.aliasFieldsList[foundElementIndex].isShow = !element.hideConditions.condition
        // this.$forceUpdate()
      }
      // Onchange select logic script
      if (element.type === 5 && element.logicscripts && element.logicscripts.length > 0) {
        if (element.logicscripts.length > 0) {
          const haschangeEvent = element.logicscripts.find(x => x.logicevent === 'change' || x.logicevent === 'onchange')
          if (haschangeEvent) {
            // eslint-disable-next-line
            let logicalFunction = new Function('instance', 'input', 'data', element[`logic_${haschangeEvent.logicname}`])
            logicalFunction(this, value, this.fields)
          }
        }
      }
    },
    surveyCheckAndSetHideFields (element, answer, index) {
      element.fieldsToHide = this.getHideFieldList(element, answer)
      /* watch for field value change */
      this.$watch(`fields.${element.name}.answer`, function (val) {
        element.fieldsToHide = this.getHideFieldList(element, val)
        this.$set(this.aliasFieldsList, index, { ...element })
      }, { deep: true })
    },
    getHideFieldList (element, answer) {
      const fieldsToHide = []
      const surveyConditionScripts = element.default_value.surveyConditionScripts || []
      surveyConditionScripts.forEach(condition => {
        if (condition.visibility) {
          if (condition.answer_value !== answer) fieldsToHide.push(condition.field)
        } else {
          if (condition.answer_value === answer) fieldsToHide.push(condition.field)
        }
      })
      return fieldsToHide
    },
    getUserName () {
      // eslint-disable-next-line
      const { first_name, last_name } = this.userDetails
      // eslint-disable-next-line
      return `${first_name || ''} ${last_name || ''}`
    },
    getUserInitial () {
      return this.userDetails.initialer || ''
    },
    getUsers () {
      return this.tenantUsers
    },
    hasConditionScript (script) {
      // if (needDecode) script = this.$formatter.$_decodeStringTobase64(script)
      const substrings = ['show =', 'show=']
      return substrings.some(v => script.indexOf(v) !== -1)
    },
    // Extension get projects method
    debouncedGetProjects (searchTerm) {
      return new Promise((resolve, reject) => {
        hostAppApi.get(`${this.getHostRefApi}get_active_projects?user_id=${this.userId}&search=${searchTerm}`)
          .then((response) => {
            resolve(response.data)
          }).catch(e => reject(e))
      })
    },
    handleFileUpload (event, propId, propName) {
      this.uploadDocumentHandler(propId, propName, event.target.files)
    },
    handleRadioButtonClick (item, options, value, field = '') {
      const radioKey = item.name + field
      if (field) {
        if (Object.prototype.hasOwnProperty.call(this.radioLastCheckHistory, radioKey) && this.radioLastCheckHistory[radioKey] === value) {
          this.fields[item.name].answer = ''
          delete this.radioLastCheckHistory[radioKey]
        } else {
          this.radioLastCheckHistory[radioKey] = value
        }
      } else {
        if (Object.prototype.hasOwnProperty.call(this.radioLastCheckHistory, radioKey) && this.radioLastCheckHistory[radioKey] === value) {
          this.fields[item.name] = ''
          delete this.radioLastCheckHistory[radioKey]
        } else {
          this.radioLastCheckHistory[item.name] = value
        }
      }
    },
    handleRadioButtonClickforRadio (item, trData, trIndex, tdGrid, tdIndex, options) {
      this.radioKey = item._id + trIndex + tdIndex
      if (Object.prototype.hasOwnProperty.call(this.radioLastCheckHistory, this.radioKey) && this.radioLastCheckHistory[this.radioKey] === options.value) {
        const index = this.aliasFieldsList.findIndex(x => x._id === item._id)
        this.aliasFieldsList[index].default_value.grid_data.data[trIndex][tdIndex].value = ''
        delete this.radioLastCheckHistory[this.radioKey]
      } else {
        this.radioLastCheckHistory[this.radioKey] = options.value
      }
    },
    handleDrag (event, item, index, trIndex, rowIndexValue, items) {
      event.dataTransfer.setData('text', JSON.stringify({ item, index, trIndex, rowIndexValue }))
    },
    handleDrop (event, item, index, mainindex, rowIndexValue, items) {
      var dataGridObj = {}
      var dropData = JSON.parse(event.dataTransfer.getData('text'))
      // var objKeys = this.dragAndDropList ? Object.keys(this.dragAndDropList) : Object.keys(this.aliasFieldsList[mainindex].default_value.grid_data.data)
      var gridData = this.aliasFieldsList[mainindex].default_value.grid_data.filterData ? this.aliasFieldsList[mainindex].default_value.grid_data.filterData : this.aliasFieldsList[mainindex].default_value.grid_data.data
      // var objKeys = this.dragAndDropList ? Object.keys(this.dragAndDropList) : Object.keys(gridData)
      var objKeys = Object.keys(gridData)
      objKeys.splice(dropData.rowIndexValue, 1)
      objKeys.splice(rowIndexValue, 0, dropData.index)
      objKeys.forEach(key => {
        dataGridObj[key] = items[key]
      })
      // if (this.dragAndDropList) {
      this.aliasFieldsList[mainindex].default_value.grid_data.filterData = {}
      this.aliasFieldsList[mainindex].default_value.grid_data.filterData = dataGridObj
      this.dragAndDropList = dataGridObj
      this.$emit('dragAndDrop', { dataGridObj, mainindex })
      // } else {
      //   console.log('out')
      //   this.aliasFieldsList[mainindex].default_value.grid_data.data = {}
      //   this.aliasFieldsList[mainindex].default_value.grid_data.data = dataGridObj
      //   this.aliasFieldsList[mainindex].default_value.grid_data.filterData = this.aliasFieldsList[mainindex].default_value.grid_data.data
      //   this.$emit('dragAndDrop', { dataGridObj, mainindex })
      // }
      this.key++
    },
    searchFilter (item, index, onClickBtn) {
      if ((this.$refs && this.$refs[`filterForm_${index}`] && this.$refs[`filterForm_${index}`][0].validate()) || onClickBtn) {
        if (item.name === this.aliasFieldsList[index].name) {
          this.$emit('filterGrid', { index, value: item.gird_filter_data.filter })
          item.default_value.grid_data.filterData = item.default_value.grid_data.data
          // var finalFilter = []
          var finalFilterValue = {}
          if (item && item.default_value && item.default_value.grid_data && item.default_value.grid_data.labels) {
            if (item.gird_filter_data.filter.length === 1 && !item.gird_filter_data.filter[0].searchField && !item.gird_filter_data.filter[0].searchValue) {
              var dragAndDropObj = this.fields.dragAndDrop && Array.isArray(this.fields.dragAndDrop) ? this.$formatter.cloneVariable(this.fields.dragAndDrop).find(x => x.mainindex === index) : this.fields.dragAndDrop
              if (this.fields && dragAndDropObj && dragAndDropObj.dataGridObj && Object.keys(this.$formatter.cloneVariable(dragAndDropObj.dataGridObj)).length === Object.keys(item.default_value.grid_data.data).length) {
                if (this.fields && dragAndDropObj && dragAndDropObj.dataGridObj && index === dragAndDropObj.mainindex) {
                  var rowKeys = Object.keys(dragAndDropObj.dataGridObj)
                  rowKeys.forEach(key => {
                    dragAndDropObj.dataGridObj[key] = item.default_value.grid_data.data[key]
                  })
                  item.default_value.grid_data.filterData = this.$formatter.cloneVariable(dragAndDropObj.dataGridObj)
                  this.$emit('dragAndDrop', { dataGridObj: item.default_value.grid_data.filterData, mainindex: index })
                } else {
                  item.default_value.grid_data.filterData = item.default_value.grid_data.data
                }
              } else {
                item.default_value.grid_data.filterData = item.default_value.grid_data.data
                this.key++
              }
              this.dragAndDropList = null
            } else {
              var searchResult = []
              item.gird_filter_data.filter.forEach((value) => {
                var object = {}
                object.search_value = value.searchValue
                object.index = item.default_value.grid_data.labels.findIndex(y => y.label === value.searchField)
                searchResult.push(object)
              })
              for (var rows in item.default_value.grid_data.data) {
                let increment = 0
                searchResult.forEach((value) => {
                  if (value && value.index.toString() && item.default_value.grid_data.labels[value.index]) {
                    switch (item.default_value.grid_data.labels[value.index].field.type) {
                      case 'string':
                        if (item.default_value.grid_data.data[rows] && item.default_value.grid_data.data[rows][value.index].value.toString().toLowerCase() === value.search_value.toString().toLowerCase()) {
                          increment++
                        }
                        break
                      case 'checkbox':
                        item.default_value.grid_data.filterData[rows][value.index].value.forEach(element => {
                          if (element.toString().toLowerCase() === value.search_value.toString().toLowerCase()) increment++
                        })
                        break
                      case 'radiobox':
                        if (item.default_value.grid_data.data[rows][value.index].value.toString().toLowerCase() === value.search_value.toString().toLowerCase()) {
                          increment++
                        }
                        break
                      case 'number':
                        if (item.default_value.grid_data.data[rows][value.index].value.toString().toLowerCase() === value.search_value.toString().toLowerCase()) {
                          increment++
                        }
                        break
                      case 'select':
                        if (item.default_value.grid_data.labels[value.index].field.typedata.isMultiSelect) {
                          item.default_value.grid_data.filterData[rows][value.index].value.forEach(element => {
                            if (element.toString().toLowerCase() === value.search_value.toString().toLowerCase()) increment++
                          })
                        } else {
                          if (item.default_value.grid_data.data[rows][value.index].value.toString().toLowerCase() === value.search_value.toString().toLowerCase()) {
                            increment++
                          }
                        }
                        break
                      case 'textarea':
                        var textAreaValue = value.search_value.toString().replaceAll(' ', '(\\s|\\n)+').toLowerCase()
                        textAreaValue = new RegExp(textAreaValue).test(item.default_value.grid_data.filterData[rows][value.index].value.toString().toLowerCase())
                        if (textAreaValue) increment++
                        break
                      case 'date':
                        if (item.default_value.grid_data.data[rows][value.index].value.toString().toLowerCase() === value.search_value.toString().toLowerCase()) {
                          increment++
                        }
                        break
                    }
                  }
                })
                if (searchResult && searchResult.length === increment) {
                  finalFilterValue[rows] = item.default_value.grid_data.data[rows]
                }
              }
              var dragAndDropObjValue = this.fields.dragAndDrop && Array.isArray(this.fields.dragAndDrop) ? this.$formatter.cloneVariable(this.fields.dragAndDrop).find(x => x.mainindex === index) : null
              if (this.fields && dragAndDropObjValue && dragAndDropObjValue.dataGridObj && finalFilterValue && Object.keys(this.$formatter.cloneVariable(dragAndDropObjValue.dataGridObj)).length === Object.keys(this.$formatter.cloneVariable(finalFilterValue)).length) {
                if (this.fields && dragAndDropObjValue && dragAndDropObjValue.dataGridObj && index === dragAndDropObjValue.mainindex && this.removeFilters) {
                  item.default_value.grid_data.filterData = this.$formatter.cloneVariable(dragAndDropObjValue.dataGridObj)
                } else {
                  item.default_value.grid_data.filterData = finalFilterValue
                }
              } else {
                item.default_value.grid_data.filterData = finalFilterValue
              }
              // item.default_value.grid_data.filterData = finalFilterValue
              this.dragAndDropList = item.default_value.grid_data.filterData
            }
            if (finalFilterValue) this.allDataGridValues.push({ value: finalFilterValue, gridName: item ? item.name : '' })
            this.$emit('filterDataGridValue', this.$formatter.cloneVariable(this.allDataGridValues))
            var dropValue = this.fields.dragAndDrop && Array.isArray(this.fields.dragAndDrop) ? this.$formatter.cloneVariable(this.fields.dragAndDrop).find(x => x.mainindex === index) : null
            // if (dropValue && dropValue.dataGridObj && Object.keys(dropValue.dataGridObj) && Object.keys(dropValue.dataGridObj).length > 0 && !this.removeFilters) {
            if (dropValue && dropValue.dataGridObj && Object.keys(dropValue.dataGridObj) && Object.keys(dropValue.dataGridObj).length > 0) {
              var dataGridObjFilter = {}
              var dragKeys = Object.keys(dropValue.dataGridObj)
              dragKeys.forEach(key => {
                if (dropValue.dataGridObj[key]) dataGridObjFilter[key] = item.default_value.grid_data.filterData[key]
              })
              if (this.aliasFieldsList[index].default_value.grid_data.filterData) {
                var allKeys = Object.keys(this.aliasFieldsList[index].default_value.grid_data.filterData)
                allKeys.forEach(key => {
                  if (!dragKeys.includes(key)) {
                    dataGridObjFilter[key] = this.aliasFieldsList[index].default_value.grid_data.data[key]
                  }
                })
              }
              //  this.aliasFieldsList[dropValue.mainindex].default_value.grid_data.filterData = dropValue.dataGridObj
              this.aliasFieldsList[index].default_value.grid_data.filterData = dataGridObjFilter
              this.dragAndDropList = this.aliasFieldsList[index].default_value.grid_data.filterData
            } else {
              this.aliasFieldsList[index].default_value.grid_data.filterData = item.default_value.grid_data.filterData
            }
          }
          this.key++
        }
      }
    },
    addnewFiler (data, index) {
      if (data.gird_filter_data.filter && data.gird_filter_data.filter.length && (this.$refs && this.$refs[`filterForm_${index}`] && this.$refs[`filterForm_${index}`][0].validate())) {
        data.gird_filter_data.filter.push({ searchValue: null, searchField: null })
        this.removeFilters = true
      }
    },
    removeFilter (data, index) {
      data.splice(index, 1)
      if (data.length === 0) {
        data.push({ searchValue: null, searchField: null })
        this.removeFilters = true
      }
      this.removeFilters = false
    },
    removeGrid (item, tdIndex, trIndex, data, datagridata, mainindex) {
      delete item[trIndex]
      delete this.aliasFieldsList[mainindex].default_value.grid_data.data[trIndex]
      delete this.aliasFieldsList[mainindex].default_value.grid_data.filterData[trIndex]
      this.$emit('dragAndDrop', { dataGridObj: this.aliasFieldsList[mainindex].default_value.grid_data.filterData, mainindex: mainindex })
      this.key++
    },
    clearDataGridFilter (item, index) {
      if (item && item.gird_filter_data && item.gird_filter_data.filter) {
        // item.gird_filter_data.filter.forEach((data, idx) => {
        //   item.gird_filter_data.filter.slice(idx)
        // })
        item.gird_filter_data.filter.splice(0, item.gird_filter_data.filter.length)
      }
      this.removeFilters = false
      if (item && item.gird_filter_data && item.gird_filter_data.filter && item.gird_filter_data.filter.length === 0) {
        item.gird_filter_data.filter.push({ searchValue: null, searchField: null })
        this.removeFilters = true
      }
      this.aliasFieldsList[index].default_value.grid_data.filterData = this.aliasFieldsList[index].default_value.grid_data.data
      this.dragAndDropList = this.aliasFieldsList[index].default_value.grid_data.filterData
      this.$emit('dragAndDrop', { dataGridObj: this.aliasFieldsList[index].default_value.grid_data.filterData, mainindex: index })
      // this.searchFilter(item, index)
      if (this.$refs && this.$refs[`filterForm_${index}`]) this.$refs[`filterForm_${index}`][0].resetValidation()
    }
  },
  beforeDestroy () {
    this.$root.$off('callRerender')
  }
}
</script>
<style >
.v-textarea textarea {
  line-height: 1rem !important;
}
.data-grid-filter-field input::placeholder{
  color: black !important;
}
</style>
